<template>
    <div>
        <ActivityDrawer 
            v-model="visible">
            <ActivityItem
                key="leave"
                @click="openEditRoleDrawer()">
                <i class="fi fi-rr-edit mr-2"></i>
                Редактировать
            </ActivityItem>
            <ActivityItem
                key="deleteRole"
                @click="confirmDeleteRole()">
                <i class="fi fi-rr-trash mr-2"></i>
                Удалить
            </ActivityItem>
        </ActivityDrawer>

        <CreateRoleDrawer 
            ref="createRoleDrawer"
            :organization="organization"/>
    </div>
</template>

<script>
import { ActivityItem, ActivityDrawer } from '@/components/ActivitySelect'
import CreateRoleDrawer from './CreateRoleDrawer.vue'
import { mapActions } from 'vuex'

export default {
    components: {
        CreateRoleDrawer,
        ActivityItem, 
        ActivityDrawer
    },
    props: {
        role: {
            type: Object,
            required: true
        },
        organization: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        ...mapActions({
            deleteRole: 'organization/deleteRole'
        }),

        openDrawer() {
            this.visible = true
        },

        openEditRoleDrawer() {
            this.$refs.createRoleDrawer.openDrawer({
                edit: true,
                role: this.role,
            })
        },
        confirmDeleteRole() {
            const self = this
            this.$confirm({
                title: 'Вы уверены, что хотите удалить роль?',
                content: '',
                okText: 'Да',
                cancelText: 'Нет',
                onOk() {
                    self.deleteRole({
                        roleId: self.role.id,
                        organizationId: self.organization.id
                    })
                },
                onCancel() {},
            })
        },
    }
}
</script>

<style scoped lang="scss">
.open_button {
    display: flex;
    justify-content: center;
    align-items: center;

    line-height: 100%;
}
.active_option {
    color: var(--blue);
}
.mob_badge{
    width: 22px;
    height: 22px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &::v-deep{
        .ant-badge{
            .ant-badge-status-dot{
                width: 10px;
                height: 10px;
            }
        }
    }
}
</style>